// import { SendEmail } from "../../../../../server/utils/sendEmail";
import React, {useState} from 'react';

const EmailTry = () => {
    const [emailData, setEmailData] = useState({
        receiverEmail: '',
        emailSubject: '',
        emailText: ''
    });

    const handleChange = (e) => {
        setEmailData({ ...emailData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/send-email', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(emailData)
            });
            const result = await response.text();
            alert(result);
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="email"
                name="receiverEmail"
                placeholder="Recipient's Email"
                value={emailData.receiverEmail}
                onChange={handleChange}
            />
            <input
                type="text"
                name="emailSubject"
                placeholder="Subject"
                value={emailData.emailSubject}
                onChange={handleChange}
            />
            <textarea
                name="emailText"
                placeholder="Email text"
                value={emailData.emailText}
                onChange={handleChange}
            />
            <button type="submit">Send Email</button>
        </form>
    );
}

export default EmailTry;