import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import "./App.css";

import UserSignups from "./pages/signups/UserSignups";
import Dishes from "./pages/dish/Dishes";
import Cook from "./pages/cook/Cook";
import Login from "./pages/user/components/Login";
import Cooks from './pages/cook/Cooks'
import Feedback from './pages/feedback/Feedback'
import OpenSignups from "./pages/signups/OpenSignups";
import Profile from "./pages/user/Profile";
import Update from "./pages/user/components/Update";
import Reset from "./pages/user/components/Reset";
import CookLogin from "./pages/cook/CookLogin";
import Miqaat from "./pages/miqaat/Miqaat";
import Event from "./pages/event/Event";
import History from "./pages/history/History";
import Pickup from "./pages/pickup/Pickup";
import ManageEvent from "./pages/event/components/ManageEvent";
import EmailTry from "./pages/event/components/EmailTry";

const httpLink = createHttpLink({
  uri: "/graphql",
});

// SETTING UP THE CONTEXT
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("id_token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <>
          <Routes>
            <Route exact path='/' element={<Login />} />
            <Route path='/login' element={<Login />} />
            <Route exact path="/openMenus" element={<OpenSignups />} />
            <Route exact path="/mySignups" element={<UserSignups />} />
            <Route exact path="/history" element={<History />} />
            <Route exact path="/pickup" element={<Pickup />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/miqaats" element={<Miqaat />} />
            <Route exact path="/events" element={<Event />} />
            <Route exact path="/dishes" element={<Dishes />} />
            <Route path="/cooks/" element={<Cooks />} />
            <Route path="/cook/:cookId" element={<Cook />} />
            <Route path="/cooklogin/:fullName" element={<CookLogin />} />
            <Route path="/feedback/:menuId" element={<Feedback />} />
            <Route path="/update/:memberId" element={<Update />} />
            <Route path="/manageEvent/:eventId" element={<ManageEvent />} />
            <Route exact path="/reset" element={<Reset />} />
            <Route path="/email" element={<EmailTry />} />
          </Routes>
        </>
      </Router>
    </ApolloProvider>
  );
}

export default App;
