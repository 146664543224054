import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { Image, Transformation } from "cloudinary-react";
import { UPDATE_EVENT_AND_UPLOAD_IMAGE } from "../gql/mutations";
import { GET_EVENT } from "../gql/queries";

const ImageUploader = (props) => {
  const { register, handleSubmit } = useForm();
  const { data } = useQuery(GET_EVENT, { variables: { eventId: props.id } });
  const event = data?.event || {};

  const [updateEventWithImage, { error }] = useMutation(UPDATE_EVENT_AND_UPLOAD_IMAGE);
  const [imageId, setImageId] = useState(event?.image);

  useEffect(() => {
    if (event?.image) {
      setImageId(event.image);
    }
  }, [event]);

  const submit = async (data, e) => {
    e.preventDefault();
    const file = data.image[0];

    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", process.env.REACT_APP_UPLOAD_PRESET);

    const response = await axios.post(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`, formData);
    const image = response.data.public_id;
    if (!image) {
      return false;
    }
    try {
      await updateEventWithImage({
        variables: {
          image: image,
          eventId: props.id
        },
      });

      let newImage = `${image}.png`
      setImageId(newImage)

    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <form id={props.id} className="uploadPhotoForm" onSubmit={handleSubmit(submit)}>
        <label htmlFor={"file-" + props.id}>

          {imageId ? (
            <Image
              cloudName={process.env.REACT_APP_CLOUD_NAME}
              publicId={imageId}
              alt={event.image}
            >
              <Transformation
                width="250"
                height="350"
                gravity="auto"
                radius="15"
                crop="fill"
                border="3px_solid_rgb:6B802A"
              />
            </Image>
          ) : (<b><u>Click here to upload invitation card and then click Save</u></b>)}
        </label>
        <input
          type="file"
          id={"file-" + props.id}
          hidden
          className="uploadPhoto"
          accept="image/*"
          {...register("image")}
        />

        <button type="submit"><p>Save</p></button>
      </form>
    </>
  );
};

export default ImageUploader;
