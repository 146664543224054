import React, { useState, useEffect } from "react";
import SectionHeader from "../../../components/SectionHeader";
import { useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { GET_ALL_USERS } from "../../user/gql/queries";
import { CREATE_EVENT } from "../gql/mutations";
import { useMediaQuery } from "../../../utils/deviceChecker";
import { GET_ALL_USERS_EVENTS, GET_ALL_EVENTS } from "../gql/queries";
import Multiselect from "multiselect-react-dropdown";
import { timeConverter } from "../../../utils/timeConverter";
import { format, parseISO } from 'date-fns';

const CreateEventForm = (props) => {
    const { refetch: refetchUsersEvents } = useQuery(GET_ALL_USERS_EVENTS);
    const { refetch: refetchAllEvents } = useQuery(GET_ALL_EVENTS);
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm();

    const { data: allEventsData } = useQuery(GET_ALL_EVENTS);
    const allEvents = allEventsData?.allEvents || [];

    const [womenOnly, setWomenOnly] = useState(false);

    const [unavailableDates, setUnavailableDates] = useState([]);

    useEffect(() => {
        const dates = allEvents.map((event) => timeConverter(event.date));
        setUnavailableDates(dates);
    }, [allEvents]);

    const validateDate = (value) => {
        const date = parseISO(value);
        const selectedDate = format(date, "EEEE, MMM d");
        if (unavailableDates.includes(selectedDate)) {
            return "This date is unavailable.";
        }
        return true;
    };

    const { data: userData } = useQuery(GET_ALL_USERS);
    let users = userData?.users || [];

    const [isHovering, setIsHovering] = useState(false);

    const categories = ["Darees", "Shitabi", "Tasbeeh", "Waras", "Shaadi", "Aqiqa"]

    const [createEvent] = useMutation(CREATE_EVENT);

    const isDesktop = useMediaQuery("(min-width: 1258px)");

    const onSubmit = async (formData) => {
        try {
            const selectedHostIds = formData.hosts.map((host) => host._id);;
            const { data } = await createEvent({
                variables: {
                    title: formData.title,
                    eventType: formData.eventType,
                    venue: formData.venue,
                    category: formData.category,
                    date: formData.date,
                    hijriDate: formData.hijriDate,
                    time: formData.time,
                    program: formData.program,
                    hosts: selectedHostIds,
                    womenOnly: womenOnly
                },
            });
            reset({
                title: "",
                category: "",
                eventType: "",
                venue: "",
                date: "",
                hijriDate: "",
                time: "",
                program: "",
                hosts: [],
            });
            setWomenOnly(false);
            props.showModal();
            refetchUsersEvents();
            refetchAllEvents();

        } catch (err) {
            console.error(err);
        }
    };



    return (
        <>

            {isDesktop && <SectionHeader title="Create Event" className="eventFormSectionHeader" />}
            <div className="createEventForm">

                <form className="eventForm" onSubmit={handleSubmit(onSubmit)}>
                    <label htmlFor="title">Title</label>
                    <input {...register("title", { required: true })} />
                    <label>Category</label>
                    <select {...register("category", { required: true })} placeholder="Category">

                        {categories && categories.map((category) => (
                            <option key={category} value={category}>{category}</option>
                        ))}
                    </select>
                    <label>Event Type</label>
                    <select {...register("eventType", { required: true })} placeholder="Public/Private">

                        <option key="public" value="public">Public (Open to All)</option>
                        <option key="private" value="private">Private</option>
                    </select>
                    <label htmlFor="venue">Venue</label>
                    <input {...register("venue", { required: true })} />
                    <label htmlFor="hijriDate">Hijri Date</label>
                    <input {...register("hijriDate", { required: true })} />
                    <label htmlFor="date">Date</label>
                    <input
                        type="date" format="yyyy-mm-dd"
                        {...register("date", {
                            required: "Date is required",
                            validate: validateDate
                        })}
                    />
                    {errors.date && <div className="error-message">{errors.date.message}</div>}

                    <label htmlFor="time">Time</label>
                    <input
                        {...register("time", { required: true, pattern: /^\d{1,2}(:\d{2})?\s(AM|PM)$/i })}
                        id="time"
                        type="text"
                        placeholder="e.g. 5:00 PM"
                        aria-label="Enter time in format hh:mm AM/PM"
                    />
                    {errors.time && <div className="error-message">Please enter a valid time (e.g., 5:00 PM).</div>}


                    <label htmlFor="program">Program</label>
                    <input {...register("program", { required: true })} />
                    <label className="checkbox-container">
                        Is this a women-only event?
                        <input
                            type="checkbox"
                            checked={womenOnly}
                            onChange={(e) => setWomenOnly(e.target.checked)}
                        />
                    </label>
                    <label>Hosts</label>
                    <p style={{ color: "#a4800f", fontSize: "15px", marginTop: "-20px" }} >Add all hosts including yourself</p>
                    <Multiselect
                        options={users.map(user => ({ _id: user._id, name: womenOnly && user.spouseName ? user.spouseName : user.fullName }))}
                        selectedValues={[]}
                        onSelect={(selectedList) => setValue("hosts", selectedList)}
                        onRemove={(selectedList) => setValue("hosts", selectedList)}
                        style={{ chips: { background: "#a4800f", option: { color: isHovering ? "#a4800f" : "#fff" } } }}
                        displayValue="name"
                        placeholder="Select Hosts"
                        onMouseEnter={() => setIsHovering(true)}
                        onMouseLeave={() => setIsHovering(false)}
                    />
                    <div className="loginButton miqaatCreate">
                        <button type="submit">
                            <h3>Create</h3>
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
};

export default CreateEventForm;