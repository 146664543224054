import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { CREATE_EVENT_RSVP } from "../gql/mutations";
import { GET_ME } from "../../user/gql/queries";
import { GET_EVENT_RSVP_FOR_USER, GET_USER_EVENT_RSVP_FOR_PARTICULAR_EVENT } from "../gql/queries";

const CreateEventRsvpForm = (props) => {

    const { refetch: refetchUsersEventRsvps } = useQuery(GET_EVENT_RSVP_FOR_USER);

    const { data: eventRsvpData } = useQuery(GET_USER_EVENT_RSVP_FOR_PARTICULAR_EVENT, {
        variables: { eventId: props.event._id },
    });

    const existingRsvp = eventRsvpData?.usersEventRsvpsForEvent.find(
        (rsvp) => rsvp.event._id === props.event._id
    );

    const isLocked = (date) => {
        return new Date() >= new Date(new Date(parseFloat(date)));
    }

    const [createRsvp] = useMutation(CREATE_EVENT_RSVP);
    const { register, handleSubmit } = useForm();

    const { data } = useQuery(GET_ME);
    const me = data?.me;
    const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8];

    const onSubmit = async (formData, event) => {
        try {
            const { data } = await createRsvp({
                variables: {
                    event: event.target.id,
                    user: me.userId,
                    men: parseInt(formData.men),
                    women: parseInt(formData.women),
                    children: parseInt(formData.children)
                },
            });
            props.showModal();
            refetchUsersEventRsvps();
        } catch (err) {
            console.error(err);
        }
    }

    return (<>
        {
            eventRsvpData &&
            <form id={props.event._id} onSubmit={handleSubmit(onSubmit)} className="eventRsvpForm" >
                <div>
                    <select {...register("men", { required: true })}
                        defaultValue={existingRsvp?.men}
                        disabled={isLocked(props.event.date)}
                        placeholder="Men">
                        <option disabled selected value=''>Men</option>
                        {numbers.map((number) => (
                            <option key={number} value={number}>{number}</option>
                        ))}
                    </select>
                    <select {...register("women", { required: true })}
                        defaultValue={existingRsvp?.women}
                        disabled={isLocked(props.event.date)}
                        placeholder="Women">
                        <option disabled selected value=''>Women</option>
                        {numbers.map((number) => (
                            <option key={number} value={number}>{number}</option>
                        ))}
                    </select>
                    <select {...register("children", { required: true })}
                        defaultValue={existingRsvp?.children}
                        disabled={isLocked(props.event.date)}
                        placeholder="Children">
                        <option disabled selected value=''>Children (5+)</option>
                        {numbers.map((number) => (
                            <option key={number} value={number}>{number}</option>
                        ))}
                    </select>
                </div>
                <input type="submit" id={props.event._id} disabled={isLocked(props.event.date)} value='Submit' />
            </form>
        }

    </>
    )
};

export default CreateEventRsvpForm;
