import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import SectionHeader from "../../../components/SectionHeader";
import { GET_ALL_EVENTS } from "../gql/queries";
import { useMediaQuery } from "../../../utils/deviceChecker";
import { timeConverter } from "../../../utils/timeConverter";
import CreateEventForm from "./CreateEventForm"

const CreateEvent = () => {

    const { data: allEventsData } = useQuery(GET_ALL_EVENTS);
    const allEvents = allEventsData?.allEvents || [];

    const isDesktop = useMediaQuery("(min-width: 1258px)");

    const showModal = () => {
        const modal = document.querySelector(".modalParent");
        modal.style.visibility = "visible";
    };

    const closeModal = () => {
        const modal = document.querySelector(".modalParent");
        modal.style.visibility = "hidden";
    };

    return (
        <>
            <h1>Host an Event</h1>
            <div className="modalParent">
                <div className="modalSuccessful">
                    <h3>Created Successfully!</h3>
                    <button className="ok" onClick={closeModal}>
                        <p>Close</p>
                    </button>
                </div>
            </div>
            {
                isDesktop ? (
                    <div className="zoneAndStatsContainer" >
                        <div className="zoneContainer">
                            <CreateEventForm showModal={showModal} />
                        </div>
                        <div className="statsContainer">
                            <div id="thaaliStats" className="thaaliAndTakhmeenStats">
                                <SectionHeader title="Unavailable Dates" className="zsSectionHeader" />
                                <div id="allEventsRow" className="dishesRow">
                                    {allEvents && allEvents.map((event) => (
                                        <>
                                            <p>{timeConverter(event.date)} - {event.category ? event.category : event.title}</p>
                                        </>
                                    ))}
                                </div>

                            </div>
                        </div>
                    </div >
                ) : (<>
                    <SectionHeader title="Unavailable Dates" className="zsSectionHeader" />
                    {allEvents &&
                        allEvents.map((event) => (
                            <div id="zoneMembersRow" className="dishesRow">
                                <p>{timeConverter(event.date)} - {event.category ? event.category : event.title}</p>
                                <hr className="miqaatRoqLine" />
                            </div>
                        ))}
                    <SectionHeader title="Create Event" className="zsSectionHeader" />
                    <div className="dishesRow">
                        <CreateEventForm />
                    </div>
                </>
                )}
        </>
    )
};

export default CreateEvent;