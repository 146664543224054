import { gql } from "@apollo/client";

export const CREATE_EVENT_RSVP = gql`
    mutation createEventRsvp($rsvpId: ID, $user: String, $event: String, $men: Int, $women: Int, $children: Int) {
        createEventRsvp(rsvpId: $rsvpId, user: $user, event: $event, men: $men, women: $women, children: $children) {
            men
            women
            children
        }
    }
`

export const CREATE_EVENT = gql`
    mutation createEvent($eventId: ID, $title: String, $hosts: [String]!, $date: String, $hijriDate: String, $time: String, $program: String, $eventType: String, $venue: String, $category: String, $womenOnly: Boolean) {
        createEvent(eventId: $eventId, title: $title, hosts: $hosts, date: $date, hijriDate: $hijriDate, time: $time, program: $program, eventType: $eventType, venue: $venue, category: $category, womenOnly: $womenOnly) {
            _id
            title
            hosts {
                _id
            }
            date
            hijriDate
            time
            program
            eventType
        }
    }
`

export const ADD_INVITEES_TO_EVENT = gql`
    mutation AddInviteesToEvent($eventId: ID!, $invitees: [InviteeInput!]!) {
        addInviteesToEvent(eventId: $eventId, invitees: $invitees) {
            _id
            event {
                _id
                title
            }
            men
            women
            children
            user {
                _id
                fullName
            }
  }
}
`

export const UPDATE_EVENT_AND_UPLOAD_IMAGE = gql`
    mutation updateEventWithImage($image: String!, $eventId: ID) {
        updateEventWithImage(image: $image, eventId: $eventId) {
            image
            _id
        }
    }
`