import React from "react";
import { useQuery } from "@apollo/client";
import { GET_SINGLE_COOK } from "./gql/queries";
import { useParams } from "react-router-dom";
import Nav from "../../components/Nav";
import Auth from "../../utils/auth";
import { Navigate } from "react-router-dom";
import FeedbackHistory from "../feedback/component/FeedbackHistory";
import UpcomingForCook from "./components/UpcomingForCook";
import PendingCookPayments from "./components/PendingCookPayments";
import PaymentHistory from "./components/PaymentHistory";

const Cook = () => {
  const { cookId: cookParam } = useParams();
  const { data } = useQuery(GET_SINGLE_COOK, { variables: { cookId: cookParam } });
  const cook = data?.cook || {};

  return (
    <>
      {!Auth.loggedIn() && <Navigate to="/login" />}
      {!Auth.isFeedbackAdmin() && <Navigate to="/profile" />}
      <div className="navAndHeader">
        <Nav />
      </div>
      <h1>{cook.fullName}</h1>
      <div className="mainContainer">
        {Auth.isFmbAdmin() &&
          <>
            <UpcomingForCook />
            <PendingCookPayments />
            <PaymentHistory />
          </>
        }
        {Auth.isFeedbackAdmin() && <FeedbackHistory />}
      </div>
    </>
  );
};

export default Cook;
