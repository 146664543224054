import React from "react";
import { useQuery } from "@apollo/client";
import { GET_ALL_USERS_EVENTS } from "./gql/queries";
import Nav from "../../components/Nav";
import Auth from "../../utils/auth";
import { Navigate } from "react-router-dom";
import EventCard from "./components/EventCard";
import CreateEvent from "./components/CreateEvent";
import HostsEvents from "./components/HostsEvents";
import UserEventRsvps from "./components/UserEventRsvps";

const Event = () => {

    const { data: eventData } = useQuery(GET_ALL_USERS_EVENTS);
    let events = eventData?.allUsersEvents || [];
    return (
        <>
            {!Auth.loggedIn() && <Navigate to="/login" />}
            <div className="navAndHeader">
                <Nav />
            </div>
            <div className="mainContainer">
                <h1>Upcoming Events</h1>
                <div className="miqaatContainer">
                    {events && events.map((event) => (
                        <EventCard event={event} />
                    ))}
                </div>
                <UserEventRsvps />
                <HostsEvents />
                <CreateEvent />
            </div>
        </>
    )
};

export default Event;

