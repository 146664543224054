import React, { useState, useRef } from "react";
import Auth from "../../../utils/auth";
import { Navigate } from "react-router-dom";
import Nav from "../../../components/Nav";
import { useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ADD_INVITEES_TO_EVENT } from "../gql/mutations";
import { GET_ALL_USERS } from "../../user/gql/queries";
import Multiselect from "multiselect-react-dropdown";
import _ from 'lodash';
import ImageUploader from "./ImageUploader";
import { GET_EVENT } from "../gql/queries";
import SectionHeader from "../../../components/SectionHeader";
import { useMediaQuery } from "../../../utils/deviceChecker";
import { GET_INVITEES_FOR_EVENT } from "../gql/queries";

const ManageEvent = () => {
    const { eventId: eventParam } = useParams();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [counts, setCounts] = useState({ men: 0, women: 0, children: 0 });
    const [invitees, setInvitees] = useState([]);
    const multiselectRef = useRef();
    const isDesktop = useMediaQuery("(min-width: 1258px)");

    const { data: eventData } = useQuery(GET_EVENT, { variables: { eventId: eventParam } });
    const event = eventData?.event || {};

    const { loading, error, data } = useQuery(GET_ALL_USERS);
    let users = data?.users || [];
    const [addInviteesToEvent] = useMutation(ADD_INVITEES_TO_EVENT);

    const { data: inviteesData } = useQuery(GET_INVITEES_FOR_EVENT, { variables: { eventId: eventParam } });
    const invitations = inviteesData?.invitees || [];

    if (loading) return <p>Loading users...</p>;
    if (error) return <p>Error loading users: {error.message}</p>;

    const resetSelectField = () => {
        multiselectRef.current.resetSelectedValues();
    };

    const handleSelectionChange = (selectedList) => {
        setSelectedUsers(selectedList);
    };

    const handleCountsChange = (e) => {
        const { name, value } = e.target;
        setCounts({ ...counts, [name]: parseInt(value) || 0 });
        if (value > 0) {
            const countsError = document.querySelector(".countsError");
            countsError.style.display = "none";
        }
    };

    const handleAddInvitees = () => {
        if (counts.men == 0 && counts.women == 0 && counts.children == 0) {
            const countsError = document.querySelector(".countsError");
            countsError.style.display = "block";
            return;
        }
        const newInvitees = selectedUsers.map((user) => ({
            userId: user._id,
            ...counts,
        }));
        setInvitees(_.unionBy(newInvitees, invitees, 'userId'));
        setCounts({ men: 0, women: 0, children: 0 });
        resetSelectField();
    };

    const handleSubmit = async () => {
        try {
            const { data } = await addInviteesToEvent({
                variables: {
                    eventId: eventParam,
                    invitees: invitees,
                },
            });
            showModal();
        } catch (error) {
            console.error("Error adding invitees:", error.message);
        }
    };

    const removeInviteeFromList = (userId) => {
        setInvitees((invitee) => invitee.filter((user) => user.userId != userId));
    }

    const showModal = () => {
        const modal = document.querySelector(".modalParent");
        modal.style.visibility = "visible";
    };

    const closeModal = () => {
        const modal = document.querySelector(".modalParent");
        modal.style.visibility = "hidden";
        window.location.reload();
    };

    return (
        <>
            {!Auth.loggedIn() && <Navigate to="/login" />}
            <div className="navAndHeader">
                <Nav />
            </div>

            <div className="modalParent">
                <div className="modalSuccessful">
                    <h3>Added Invitees Successfully!</h3>
                    <button className="ok" onClick={closeModal}>
                        <p>Close</p>
                    </button>
                </div>
            </div>
            <div className="mainContainer manageEvent">
                <SectionHeader title="Upload Invitation" className='sectionHeader' />
                <div className="uploadInvitation dishContainer">
                    <div id="uploadInvite" className="dishesRow">
                        <ImageUploader id={eventParam} ></ImageUploader>
                    </div>
                </div>
                {event.eventType === "private" && <>
                    <SectionHeader title="Invite" className='sectionHeader' />

                    <div className="addInviteeForm dishContainer">
                        <div className="inviteInstructions">
                            <ul>
                                <li> Select one or multiple guests from the drop-down </li>
                                <li> ⁠Enter number of invites for the guests (if you select multiple guests, the same invite count will apply across all guests) </li>
                                <li> ⁠Click on the Add button to add the invitees to your list</li>
                                <li> ⁠Review invite counts for your guests </li>
                                <li> ⁠Click Submit to send your invites</li>
                            </ul>
                        </div>
                        <div className="dishesRow">
                            <div className="inviteesDropdown">
                                <Multiselect
                                    options={users.map(user => ({ _id: user._id, name: event.womenOnly && user.spouseName ? user.spouseName : user.fullName }))}
                                    selectedValues={selectedUsers}
                                    displayValue="name"
                                    placeholder="Click to Select Guests"
                                    style={{
                                        multiselectContainer: { background: "white", border: "2px solid #a4800f", borderRadius: "15px", marginBottom: "10px" },
                                        searchBox: { border: "none", fontSize: "10px", minHeight: "50px" },
                                        chips: { background: "#a4800f" }
                                    }}
                                    onSelect={handleSelectionChange}
                                    onRemove={handleSelectionChange}
                                    ref={multiselectRef}
                                />
                            </div>

                            <div className="inviteeCounts">
                                <label>Men</label>
                                <input
                                    type="number"
                                    name="men"
                                    min="0"
                                    value={counts.men}
                                    onChange={handleCountsChange}
                                />
                                <label>Women</label>
                                <input
                                    type="number"
                                    name="women"
                                    min="0"
                                    value={counts.women}
                                    onChange={handleCountsChange}
                                />
                                <label>Children</label>
                                <input
                                    type="number"
                                    name="children"
                                    min="0"
                                    value={counts.children}
                                    onChange={handleCountsChange}
                                />
                            </div>
                            <div className="addInviteeBtnContainer" >
                                <button className="addInviteeButton" onClick={handleAddInvitees}>Add</button>
                            </div>
                        </div>
                        <div className="countsError" style={{ display: "none" }}>Please select an invitee count.</div>
                        <div className="inviteesList">
                            {invitees.length > 0 && <h3 style={{ marginTop: "10px", textAlign: "center" }}>Selected Invitees</h3>}
                            <ul>
                                {invitees.map((invitee, index) => {
                                    const user = data?.users?.find((u) => u._id === invitee.userId);
                                    const displayName = event?.womenOnly && user?.spouseName ? user.spouseName : user?.fullName;

                                    return (
                                        <li key={index}>
                                            <div>
                                                {displayName || "Loading..."}
                                            </div>
                                            {isDesktop ? <div>Men: {invitee.men} </div> : <div>M: {invitee.men} </div>}
                                            {isDesktop ? <div>Women: {invitee.women} </div> : <div>W: {invitee.women} </div>}
                                            {isDesktop ? <div>Children: {invitee.children} </div> : <div>C: {invitee.children} </div>}
                                            <button
                                                className="cancelButton"
                                                onClick={() => removeInviteeFromList(invitee.userId)}
                                            >
                                                Remove
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="loginButton">
                            <button type="submit" onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>
                </>
                }{
                    event.eventType === "private" &&
                    <>
                        <SectionHeader title="Invitees List" className='sectionHeader' />
                        <div className="dishContainer">
                            {
                                invitations ? invitations.map((invitation) => (
                                    <div>
                                        <div id="upcomingDishesRow" className="dishesRow">
                                            <p className="miqaatText">{event?.womenOnly && invitation?.user?.spouseName ? invitation?.user.spouseName : invitation?.user?.fullName}</p>
                                            <p>Men: {invitation.men}</p>
                                            <p>Women: {invitation.women}</p>
                                            <p>Children: {invitation.children}</p>
                                        </div>
                                        <hr className="dishRowLine" />
                                    </div>
                                )) : <p className="errMsg" style={{ textAlign: "center", fontSize: "20px", paddingBottom: "20px" }}>
                                    No invitations sent
                                </p>
                            }
                        </div>
                    </>
                }

            </div >
        </>
    );
};

export default ManageEvent;
